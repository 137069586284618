import React from "react";
import styles from "./HeadcountCard.module.scss";
import { connect } from "react-redux";
import * as Actions from "../../../../../redux/actions/manageHeadcountActions";
import IconOnlyButton from "../../../../presentation/unity/IconOnlyButton";
import moment from "moment";

const DisplayHeadcountCard = (props) => {
  const { baseHeadcount, disableButtons, projectedDates } = props;
  console.log("🚀 ~ DisplayHeadcountCard ~ projectedDates:", projectedDates);
    let currentDate = moment().format('YYYY-MM-DD');
    baseHeadcount.isEditable = (projectedDates.map(range =>moment(currentDate).isSameOrAfter(moment(range.startDate.value))
    && moment(currentDate).isSameOrBefore(moment(range.endDate.value))||(moment(currentDate).isSameOrAfter(moment(range.endDate.value))))).includes(false)?true:false;
    
  const SmallIcon = ({ icon, color }) => (
    <svg className="em-c-icon em-c-icon--small" style={{ color: color }}>
      <use
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xlinkHref={`/images/em-icons.svg#${icon}`}
      />
    </svg>
  );

  return (
    <div className={styles.card_container_grouped}>
      <div>
        <div className={styles[`pill_${baseHeadcount.status.toLowerCase()}`]}>
          {baseHeadcount.status}
        </div>
      </div>

      <div className={styles.card_button_container}>
        {baseHeadcount.status === "New" && (
          <IconOnlyButton
            btnClass={styles.card_button}
            disabled={disableButtons}
            title={"Delete this Headcount!"}
            icon={"trashcan"}
            iconColor={"red"}
            onClick={() =>
              props.deleteHeadcountsByGrouping(baseHeadcount?.headCountGrouping)
            }
          />
        )}
        <IconOnlyButton
          btnClass={styles.card_button}
          disabled={disableButtons|| !baseHeadcount.isEditable }
          icon={"edit"}
          title={"Edit this Headcount!"}
          onClick={() => props.editHeadcount(baseHeadcount.headcountRequestID)}
        />
      </div>
      <div>
        <div className={styles.card_column_20}>
          <div className={styles.icon_image}>
            <SmallIcon icon={"group"} />
            <strong className={styles.icon_text_left}>
              {baseHeadcount.organization || "-"}
            </strong>
          </div>
          <div>
            <strong>Usage Type: </strong>
            {baseHeadcount.usageType || "-"}
          </div>
          <div>
            <strong>Role: </strong>
            {baseHeadcount.positionRole || "-"}
          </div>
        </div>
        <div className={styles.card_column_25}>
          <div>
            <strong>Department: </strong>
            {baseHeadcount.department || "-"}
          </div>
          <div>
            <strong>Section: </strong>
            {baseHeadcount.section || "-"}
          </div>
          <div>
            <strong>Area: </strong>
            {baseHeadcount.area || "-"}
          </div>
        </div>
        <div className={styles.card_column_15}>
          <div
            className={
              baseHeadcount.shiftID === 1
                ? styles.icon_box_day
                : styles.icon_box_night
            }
          >
            <div className={styles.icon_box_position}>
              <SmallIcon icon={baseHeadcount.shiftID === 1 ? "sun" : "moon"} />
            </div>
            <span className={styles.icon_box_text}>
              {baseHeadcount.shiftID === 1 ? "Day" : "Night"}
            </span>
          </div>
          <div>
            <span className={styles.icon_text_right}>Billable </span>
            <SmallIcon
              icon={baseHeadcount.billable ? "check" : "x-filled"}
              color={baseHeadcount.billable ? "green" : "red"}
            />
          </div>
          <div>
            <span className={styles.icon_text_right}>Schedulable </span>
            <SmallIcon
              icon={baseHeadcount.schedulable ? "check" : "x-filled"}
              color={baseHeadcount.schedulable ? "green" : "red"}
            />
          </div>
          <div>
            <span className={styles.icon_text_right}>Shift Imbalance </span>
            <SmallIcon
              icon={baseHeadcount?.headCountGrouping ? "check" : "x-filled"}
              color={baseHeadcount?.headCountGrouping ? "green" : "red"}
            />
          </div>
        </div>
        <div className={styles.card_column_20}>
          <div>
            <SmallIcon icon={"calendar"} />
            <strong className={styles.icon_text_left}>Dates</strong>
          </div>
          <div style={{ overflowY: "scroll", height: "13rem" }}>
            {projectedDates.map(({ startDate, endDate }, i) => (
              <div key={i} className={styles.table_box}>
                <table className={`${styles.table_date} ${styles.card_table}`}>
                  <tbody>
                    <tr>
                      <td
                        className={
                          styles.card_td + " " + styles.card_td_first_child
                        }
                      >
                        {startDate.value}{" "}
                      </td>
                      <td
                        className={
                          styles.card_td + " " + styles.card_td_last_child
                        }
                      >
                        {endDate.value}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.card_column_20}>
          <div>
            <SmallIcon icon={"suitcase"} />
            <strong className={styles.icon_text_left}>Company</strong>
          </div>
          <div className={styles.table_box}>
            <table className={styles.card_table}>
              <tbody>
                <tr>
                  <td
                    className={
                      styles.table_company +
                      " " +
                      styles.card_td +
                      " " +
                      styles.card_td_first_child
                    }
                  >
                    {baseHeadcount.company || "-"}{" "}
                  </td>
                  <td
                    className={styles.card_td + " " + styles.card_td_last_child}
                  >
                    {baseHeadcount.quantity || "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    editHeadcount: (id) => dispatch(Actions.editHeadcount(id)),
    deleteHeadcountsByGrouping: (headCountGrouping) =>
      dispatch(Actions.deleteHeadcountByGrouping(headCountGrouping)),
  };
};

export default connect(null, mapDispatchToProps)(DisplayHeadcountCard);

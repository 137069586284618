export const MANAGE_HEADCOUNTS_LOAD = "MANAGE_HEADCOUNTS_LOAD";
export const MANAGE_HEADCOUNTS_CLONE = "MANAGE_HEADCOUNTS_CLONE";
export const MANAGE_HEADCOUNTS_RESET = "MANAGE_HEADCOUNTS_RESET";
export const MANAGE_HEADCOUNTS_EDIT = "MANAGE_HEADCOUNTS_EDIT";
export const MANAGE_HEADCOUNTS_EDIT_SAVE = "MANAGE_HEADCOUNTS_EDIT_SAVE";
export const MANAGE_HEADCOUNTS_GROUPED_EDIT_SAVE =
  "MANAGE_HEADCOUNTS_GROUPED_EDIT_SAVE";
export const MANAGE_HEADCOUNTS_EDIT_CANCEL = "MANAGE_HEADCOUNTS_EDIT_CANCEL";
export const MANAGE_HEADCOUNTS_CREATE = "MANAGE_HEADCOUNTS_CREATE";
export const MANAGE_HEADCOUNTS_DELETE = "MANAGE_HEADCOUNTS_DELETE";
export const MANAGE_HEADCOUNTS_DELETE_GROUPED =
  "MANAGE_HEADCOUNTS_DELETE_GROUPED";

export const loadHeadcounts = (ccr) => ({
  type: MANAGE_HEADCOUNTS_LOAD,
  payload: ccr,
});

export const cloneHeadcount = (headcountId) => ({
  type: MANAGE_HEADCOUNTS_CLONE,
  payload: headcountId,
});

export const resetAllHeadcounts = () => ({
  type: MANAGE_HEADCOUNTS_RESET,
  payload: null,
});

export const editHeadcount = (headcountId) => ({
  type: MANAGE_HEADCOUNTS_EDIT,
  payload: headcountId,
});

export const saveEditHeadcount = (headcount, shiftImbalance) => ({
  type: MANAGE_HEADCOUNTS_EDIT_SAVE,
  payload: { headcount, shiftImbalance },
});

export const saveGroupedEditHeadcount = (baseHeadcount, projectedDates, projectedDatesArr) => ({
  type: MANAGE_HEADCOUNTS_GROUPED_EDIT_SAVE,
  payload: { baseHeadcount, projectedDates,projectedDatesArr },
});

export const cancelEditHeadcount = () => ({
  type: MANAGE_HEADCOUNTS_EDIT_CANCEL,
  payload: null,
});

export const createHeadcount = () => ({
  type: MANAGE_HEADCOUNTS_CREATE,
  payload: null,
});

export const deleteHeadcount = (headcountId) => ({
  type: MANAGE_HEADCOUNTS_DELETE,
  payload: headcountId,
});

export const deleteHeadcountByGrouping = (headCountGrouping) => ({
  type: MANAGE_HEADCOUNTS_DELETE_GROUPED,
  payload: headCountGrouping,
});
